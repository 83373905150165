import React from 'react'
import styles from './styles.module.scss';
import Card from 'components/card/Card';
import ImageCard1 from 'assets/svg/manageAndOrganization.svg';
import ImageCard2 from 'assets/svg/legalAndAdministrative.svg';
import ImageCard3 from 'assets/svg/financialAndFiscal.svg';
import { FormattedMessage } from "react-intl";
import Carousel from 'components/carousel/Carousel';

const SERVICES = [
  "services_management_organization",
  "services_legal_administrative",
  "services_financial_fiscal",
]

const MANAGEMENT_ORGANIZATION = [
  "services_business_plan_development",
  "services_team_selection_and_training",
  "services_hr_management",
  "services_internal_organization"
];

const LEGAL_ADMINISTRATIVE = [
  "services_contract_review",
  "services_permit_acquisition",
  "services_gdpr",
  "services_corporate_law",
  "services_new_tech_law",
  "services_ecommerce_low",
];

const FINANCIAL_FISCAL = [
  "services_financial_plan",
  "services_funding_search",
  "services_treasury_management",
  "services_audits_management",
  "services_controlling_tasks",
  "services_risk_management",
];

function Services() {

  const content = (array, title) => {
    return (
      <div className={styles.content__Wrapper}>
        <div className={styles.content__Title}><span>{title}</span></div>
        <ul>
          {array.map((e, idx) => {
            return (
              <li className={styles.content__List} key={e}>
                <FormattedMessage id={e}>
                </FormattedMessage>
              </li>)
          })}
        </ul>
      </div>
    )
  }

  const slides = [
    <Card imgUrl={ImageCard1} >{content(MANAGEMENT_ORGANIZATION, <FormattedMessage id={SERVICES[0]}></FormattedMessage>)}</Card>,
    <Card imgUrl={ImageCard2} >{content(LEGAL_ADMINISTRATIVE, <FormattedMessage id={SERVICES[1]}></FormattedMessage>)}</Card>,
    <Card imgUrl={ImageCard3} >{content(FINANCIAL_FISCAL, <FormattedMessage id={SERVICES[2]}></FormattedMessage>)}</Card>,
  ]

  return (
    <section className={styles.main}>
      <h2 className={styles.title}><FormattedMessage id="services_my_services"></FormattedMessage></h2>
      <Carousel pages={slides}></Carousel>
      <div className={styles.container}>
        <Card imgUrl={ImageCard1} >{content(MANAGEMENT_ORGANIZATION, <FormattedMessage id={SERVICES[0]}></FormattedMessage>)}</Card>
        <Card imgUrl={ImageCard2} >{content(LEGAL_ADMINISTRATIVE, <FormattedMessage id={SERVICES[1]}></FormattedMessage>)}</Card>
        <Card imgUrl={ImageCard3} >{content(FINANCIAL_FISCAL, <FormattedMessage id={SERVICES[2]}></FormattedMessage>)}</Card>
      </div>
    </section>
  )
}

export default Services
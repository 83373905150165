import React, { useContext, useRef } from 'react'
import styles from './styles.module.scss';
import Main from 'screens/main/Main';
import Services from 'screens/services/Services';
import Solutions from 'screens/solutions/Solutions';
import Calendar from 'screens/calendar/Calendar';
import Contact from 'screens/contact/Contact';
import { IntlProvider } from 'react-intl'
import { MESSAGES_EN } from 'lenguage/english';
import { MESSAGES_ES } from 'lenguage/spanish';
import { Language } from 'scripts/languageContext';

function Home() {

  const { language } = useContext(Language);
  let message = MESSAGES_EN;

  switch (language) {
    case "Es":
      message = MESSAGES_ES;
      break;
  
    default:
      message = MESSAGES_EN;
      break;
  }

  const ref = useRef(null);

  const handleClick = (ref) => {
    ref.current?.scrollIntoView();
    console.log("pato")
  }

  return (
      <main className={styles.main}>
        <IntlProvider locale="En" defaultLocale="En" messages={message}>
          <Main clickEvent={() => handleClick(ref)}></Main>
          <Services></Services>
          <Solutions></Solutions>
          <Calendar reference={ref}></Calendar>
          <Contact></Contact>
        </IntlProvider>
      </main>
  ) 
}

export default Home
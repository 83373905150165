import React from 'react';
import styles from './styles.module.scss';
import Page from 'components/page/Page';
import mainImage from 'assets/images/main-image.webp';
import mainMobile from 'assets/images/main-mobile.webp';
import korumeiBg from 'assets/svg/mainBg.svg';
import korumeiMobile from 'assets/svg/mainMobile.svg';
import zulligerLogo from 'assets/svg/zulligerLogo.svg';
import { FormattedMessage } from "react-intl";
import Header from 'components/header/Header';

function Main({clickEvent}) {
  return (
    <section className={styles.main}>
      <picture>
        <source srcSet={korumeiBg} media="(min-width: 1050px)" />
        <img src={korumeiMobile} alt="main_background" className={styles.mainBg} />
      </picture>
      <Header></Header>
      <Page hasBottom margin="-200px 0 0 0">
        <div className={styles.wrapper}>

          <article className={styles.container}>
            <div className={styles.pictureCard}>
              <picture>
                <source srcSet={mainImage} media="(min-width: 1050px)" />
                <img src={mainMobile} alt="main" className={styles.mainImage} />
              </picture>
            </div>
            <div className={styles.content_wrapper}>
              <img src={zulligerLogo} alt="logo zulliger" className={styles.logo} />
              <p className={styles.paragraph}><FormattedMessage id={"text_main_paragraph_1"}></FormattedMessage></p>
              <p className={styles.paragraph}><FormattedMessage id={"text_main_paragraph_2"}></FormattedMessage></p>
              <p className={styles.paragraph}><FormattedMessage id={"text_main_paragraph_3"}></FormattedMessage></p>
              <p className={styles.paragraph}><FormattedMessage id={"text_main_paragraph_4"}></FormattedMessage></p>
              <h3 className={styles.subText}><FormattedMessage id={"looking_foward_meeting_you"}></FormattedMessage></h3>
              <button onClick={clickEvent}className={styles.contactBtn}><FormattedMessage id={"contact_me"}></FormattedMessage></button>
            </div>
          </article>
        </div>
      </Page>
    </section>
  )
}

export default Main
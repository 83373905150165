export const MESSAGES_EN = {

    text_main_paragraph_1: 'I have founded technology startups in Spain, Germany, and Portugal, and led both local and international companies and teams. For over 15 years, I have provided strategic guidance to technology startups as a CFO, COO, and CEO.',
    text_main_paragraph_2: 'My motto "Prevention means Quality" made me understand and anticipate the financial, legal, and operational issues that founders commonly face, and be able to tailor solutions accordingly.',
    text_main_paragraph_3: 'Whether you require assistance with your business plan, financial advice, legal procedures, operational management, or need expert counsel for a decision-making process.',
    text_main_paragraph_4: 'You can reach out for Free whenever you need.',
    looking_foward_meeting_you: "Looking forward meeting you.",
    contact_me: "Contact Me",

    services_my_services: "My Services",

    services_management_organization: "MANAGEMENT AND ORGANIZATION",
    services_legal_administrative: "LEGAL AND ADMINISTRATIVE",
    services_financial_fiscal: "FINANCIAL AND FISCAL",

    services_business_plan_development: "Business plan development.",
    services_team_selection_and_training: "Team selection and training.",
    services_hr_management: "Hr management.",
    services_internal_organization: "Internal organization.",

    services_contract_review: "Contract review.",
    services_permit_acquisition: "Permit acquisition.",
    services_gdpr: "Gdpr.",
    services_corporate_law: "Corporate law.",
    services_new_tech_law: "New tech law.",
    services_ecommerce_low: "Ecommerce low.",

    services_financial_plan: "Financial plan.",
    services_funding_search: "Funding search.",
    services_treasury_management: "Treasury management.",
    services_audits_management: "Audits management.",
    services_controlling_tasks: "Controlling tasks.",
    services_risk_management: "Risk management.",

    solutions_taylor_made_solutions: "Tailor made solutions",

    solutions_visionary: "VISIONARY",
    solutions_pre_seed: "PRE SEED",
    solutions_early_startup: "EARLY STARTUP",
    solutions_growing_startup: "GROWING STARTUP",

    solutions_company_formation: "Company Formation.",
    solutions_business_strategy: "Business Strategy.",
    solutions_business_plan: "Business Plan.",
    solutions_financial_plan: "Financial Plan.",

    solutions_create_the_mvp: "Create the MVP (Minimum Viable Product).",
    solutions_due_diligence: "Due Diligence.",
    solutions_secure_funding: "Secure Funding.",
    solutions_team_consolidation: "Team Consolidation.",
    solutions_contracts_and_internal_processes: "Contracts and Internal Processes.",

    solutions_quality_processes: "Quality Processes.",
    solutions_departmental_improvement: "Departmental Improvement.",
    solutions_establish_company_culture: "Establish Company Culture.",
    solutions_team_expansion_and_training: "Team Expansion and Training.",

    solutions_financial_state_enhancement: "Financial State Enhancement.",
    solutions_process_optimization: "Process Optimization.",
    solutions_customer_care: "Customer Care.",
    solutions_risk_management: "Risk Management.",

    calendar_calendar: "Calendar",
    calendar_text: "Set a call with me",

    contact_website: "Website:",
    contact_email: "E-Mail:",
    contact_address: "Adress:",

    contact_legal_conditions: "Legal terms"
}

import React, { createContext, useState } from 'react'

export const Language = createContext();

function LenguageProvider({children}) {

  const [language, setLanguage] = useState("En");
  
  return (
    <Language.Provider value={{language, setLanguage}}>
        {children}
    </Language.Provider>
  )
}

export default LenguageProvider
import React, { useRef } from 'react'
import styles from './styles.module.scss';
import Page from 'components/page/Page';
import { FormattedMessage } from "react-intl";
import FooterLogo from 'assets/svg/footerSvg.svg';
import FooterLogoMobile from 'assets/svg/footerMobile.svg';
import ContactoLegal from 'components/contactoLegal/ContactoLegal';

function Contact() {

  const refLegal = useRef();

  const openDialog = (ref) => {
    ref.current?.showModal()
  }

  const closeDialog = (ref) => {
    ref.current?.close()
  }

  return (
    <section className={styles.main}>
      <Page hasTop>
        <div className={styles.content_wrapper}>
          <picture>
            <source srcSet={FooterLogo} media="(min-width: 1050px)" />
            <img src={FooterLogoMobile} className={styles.footerImg} alt="Footer Logo" />
          </picture>
          <article className={styles.info_container}>
            <button onClick={() => openDialog(refLegal)} className={styles.footerBtn}><FormattedMessage id={"contact_legal_conditions"}></FormattedMessage></button>
          </article>
        </div>
        <dialog ref={refLegal} className={styles.dialog}>
          <div className={styles.dialog_container}>
            <ContactoLegal><button className={styles.closeX} onClick={() => closeDialog(refLegal)}>X</button></ContactoLegal>
          </div>
        </dialog>
        
      </Page>
    </section>
  )
}

export default Contact
import React, { useContext, useRef, useState } from 'react';
import styles from './styles.module.scss';
import lenguageLogo from 'assets/svg/lenguage.svg';
import useOutsideClick from 'scripts/useOutsideClick';
import { Language } from 'scripts/languageContext';
import flagEn from 'assets/svg/flag_gb.svg'
import flagEs from 'assets/svg/flag_es.svg'

function Header() {

  const { setLanguage } = useContext(Language);
  const [ actualLanguage, setNewLanguage ] = useState("En")
  const [isLang, activeLang] = useState(false);
  const ref = useRef(null);

  useOutsideClick(ref, () => {
    activeLang(false)
  });

  const handleLenguageClick = (leng) => {
    setLanguage(leng);
    setNewLanguage(leng)
    activeLang(false);
  }

  return (
    <header className={styles.header}>
        <div onClick={() => activeLang(!isLang)} ref={ref} className={styles.header_content}>
        <span>{actualLanguage}</span>
        <div className={isLang ? styles.dropdownLenguageOn : styles.dropdownLenguageOff}>
            <button onClick={() => handleLenguageClick("En")}><span>En</span><img className={styles.flagImg} src={flagEn} alt='flag_En'/></button>
            <button onClick={() => handleLenguageClick("Es")}><span>Es</span><img className={styles.flagImg} src={flagEs} alt='flag_En'/></button>
        </div>
        <img src={lenguageLogo} alt="world_logo" />
        </div>
    </header>
  )
}

export default Header
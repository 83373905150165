import React from 'react'

function BottomVector() {
    return (
        <svg  fill="none" version="1.1" viewBox="0 0 1919.9 252.02" xmlns="http://www.w3.org/2000/svg">
            <rect  ry="0" fill="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <g transform="translate(-4.3298e-8 -1055.4)" filter="drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.8))">
                <path d="m0.0028225 1225.3s-6-87.4 562.6-61.6c345.1 15.6 610.7 48.4 802.1 63.5 488.7 38.6 555.7 42.9 555.3-31v-141.2h-1920z" fill="#040c39"/>
            </g>
        </svg>
    )
}

export default BottomVector
import React, { useRef, useEffect, useState } from 'react'
import styles from './styles.module.scss';

function Carousel({ pages }) {
    
    const [firstPosition, setFirstPosition] = useState();
    const [checkedIndex, setCheckedIndex] = useState(0);
    
    const slideRef = useRef([]);
    const dotRef = useRef([]);

    useEffect(() => {
        slideRef.current = slideRef.current.slice(0, pages.length);
        dotRef.current = dotRef.current.slice(0, pages.length);
     }, [pages]);
    
    const slideMap = pages.map(
        (page, idx) => {
            return (
                <div 
                    key={`carousel-slide-${idx}`} 
                    onMouseDown={(e) => setFirstPosition(e.clientX)} 
                    onMouseUp={(e) => handleDrag(e, idx)} 
                    ref={{current: page}} 
                    className={styles.slide}
                    >
                        {page}
                </div>
        )})
    const slides = [...slideMap];
     
    const handleMovement = (idx) => {
        slides[idx].ref.current.scrollIntoView();
    }

    const handleDrag = (ref, index) => {

        const MARGIN = 20;

        switch (index) {
            case 0:
                if (ref.clientX + MARGIN < firstPosition) {
                    handleMovement(index + 1);
                    setCheckedIndex(index + 1)
                } 
                break;
            case slides.length - 1:
                if (ref.clientX - MARGIN > firstPosition) {
                    handleMovement(index - 1);
                    setCheckedIndex(index -1)
                }
                break;
            default:
                if (ref.clientX + MARGIN < firstPosition) {
                    handleMovement(index + 1);
                    setCheckedIndex(index + 1)
                } 
                if (ref.clientX - MARGIN > firstPosition) {
                    handleMovement(index - 1);
                    setCheckedIndex(index -1)
                }
                break;
        }
    }

    const dots = slides.map((e, idx) => {
        return (
            <div key={`carousel-dots-${idx}`}>
                <label htmlFor={`radio-button-${idx}`}></label>
                <input 
                    ref={{current: this}} 
                    checked={idx === checkedIndex}
                    name="carousel"
                    id={`radio-button-${idx}`}
                    type="radio"
                    className={styles.dots}
                    onClick={() => handleMovement(idx)} 
                    key={`carousel-dot-${idx}`}
                    onChange={() => setCheckedIndex(idx)}
                />
            </div>
        )
    })

    return (
        <div className={styles.main}>
            <div className={styles.carousel}>
                {slides}
            </div>
            <div className={styles.dots_container}>
                {dots}
            </div>
        </div>
    )
}

export default Carousel
export default function classList(classes, stylesObject) {
  try {
    const classArray = Object.entries(classes).filter(entry => entry[1]).map(entry => entry[0]);
    const classMatched = Object.entries(stylesObject).filter(element => classArray.includes(element[0]));
    const styles = classMatched.map((item) => item[1]);
    return styles.join(' ');
  } catch (error) {
    console.warn('Class Error')
    return '';
  }
}
import React from 'react';
import styles from './styles.module.scss';
import Page from 'components/page/Page';
import logoPeregil from 'assets/svg/logoPeregil.svg';
import banner from 'assets/svg/banner.svg';
import { FormattedMessage } from "react-intl";

const SOLUTIONS = [
  "solutions_visionary",
  "solutions_pre_seed",
  "solutions_early_startup",
  "solutions_growing_startup",
]
  
const VISIONARY = [
  "solutions_company_formation",
  "solutions_business_strategy",
  "solutions_business_plan",
  "solutions_financial_plan",
];

const PRE_SEED = [
  "solutions_create_the_mvp",
  "solutions_due_diligence",
  "solutions_secure_funding",
  "solutions_team_consolidation",
  "solutions_contracts_and_internal_processes",
];

const EARLY_STARTUP = [
  "solutions_quality_processes",
  "solutions_departmental_improvement",
  "solutions_establish_company_culture",
  "solutions_team_expansion_and_training",
];

const GROWING_STARTUP = [
  "solutions_financial_state_enhancement",
  "solutions_process_optimization",
  "solutions_customer_care",
  "solutions_risk_management",
];

function Solutions() {

  const content = (array, title) => {
    return (
      <div className={styles.content__Wrapper}>
        <div className={styles.content__Title}><span>{title}</span></div>
        <ul>
          {array.map((e, idx) => { 
            return (
            <li className={styles.content__List} key={e}>
              <FormattedMessage id={e}>
              </FormattedMessage>
            </li>) 
          })}
        </ul>
      </div>
    )
  }

  return (
    <section className={styles.main}>
      <Page hasTop hasBottom>
        <article className={styles.container}>
          <h2 className={styles.title}><FormattedMessage id="solutions_taylor_made_solutions"></FormattedMessage></h2>
          <div className={styles.content_grid}>
            <div className={styles.mobile_logo}><img src={logoPeregil} alt="logo" /></div>
            <div className={styles.grid1}><div className={styles.item}>{content(VISIONARY, <FormattedMessage id={SOLUTIONS[0]}></FormattedMessage>)}</div></div>
            <div className={styles.mobile_banner}><img src={banner} alt="banner" /></div>
            <div className={styles.grid2}><div className={styles.item}>{content(PRE_SEED, <FormattedMessage id={SOLUTIONS[1]}></FormattedMessage>)}</div></div>
            <div className={styles.mobile_banner}><img src={banner} alt="banner" /></div>
            <div className={styles.grid3}><div className={styles.item}>{content(EARLY_STARTUP, <FormattedMessage id={SOLUTIONS[2]}></FormattedMessage>)}</div></div>
            <div className={styles.mobile_banner}><img src={banner} alt="banner" /></div>
            <div className={styles.grid4}><div className={styles.item}>{content(GROWING_STARTUP, <FormattedMessage id={SOLUTIONS[3]}></FormattedMessage>)}</div></div>
          </div>
        </article>
      </Page>
    </section>
  )
}

export default Solutions
export const MESSAGES_ES = {

    text_main_paragraph_1: 'He fundado startups tecnológicas en España, Alemania y Portugal y dirigido empresas y equipos locales e internacionales. Por mas de 15 años he brindando orientación estratégica a startups tecnológicas como CFO, COO y CEO.',
    text_main_paragraph_2: 'Mi lema “Calidad es prevención” me lleva a entender y anticipar los problemas financieros, legales y operativos que los fundadores suelen enfrentar y poder desarrollar soluciones a medida.',
    text_main_paragraph_3: 'Ya sea que necesites ayuda con tu plan de negocios, asesoramiento financiero, trámites legales, gestiones operativas o tengas que tomar una decisión que requiera consejo experto.',
    text_main_paragraph_4: 'Puedes llamarme gratis en el momento que me necesites.',
    looking_foward_meeting_you: "Espero tu llamada.",
    contact_me: "Contáctame",

    services_my_services: "Mis Servicios",

    services_management_organization: "GESTIÓN Y ORGANIZACIÓN",
    services_legal_administrative: "LEGAL Y ADMINISTRATIVO",
    services_financial_fiscal: "FINANCIERO Y FISCAL",

    services_business_plan_development: "Elaboración del business plan.",
    services_team_selection_and_training: "Selección y formación de equipos.",
    services_hr_management: "Gestión de hhrr.",
    services_internal_organization: "Organización interna.",

    services_contract_review: "Revisión de contrato.s",
    services_permit_acquisition: "Obtención de permisos.",
    services_gdpr: "Gdpr.",
    services_corporate_law: "Leyes corporativas.",
    services_new_tech_law: "Leyes nuevas tecnologías.",
    services_ecommerce_low: "Leyes ecommerce.",

    services_financial_plan: "Plan financiero.",
    services_funding_search: "Búsqueda de financiamiento.",
    services_treasury_management: "Tesorería.",
    services_audits_management: "Auditorias.",
    services_controlling_tasks: "Controlling.",
    services_risk_management: "Gestión de riesgo.",

    solutions_taylor_made_solutions: "Soluciones a Medida",

    solutions_visionary: "VISIONARIO",
    solutions_pre_seed: "PRE SEED",
    solutions_early_startup: "EARLY STARTUP",
    solutions_growing_startup: "GROWING STARTUP",

    solutions_company_formation: "Constitución de empresa.",
    solutions_business_strategy: "Estrategia de negocio.",
    solutions_business_plan: "Plan de negocio.",
    solutions_financial_plan: "Plan financiero.",

    solutions_create_the_mvp: "Crear el mvp.",
    solutions_due_diligence: "Due diligence.",
    solutions_secure_funding: "Conseguir financiación.",
    solutions_team_consolidation: "Consolidar el equipo.",
    solutions_contracts_and_internal_processes: "Contratos y procesos internos.",

    solutions_quality_processes: "Procesos de calidad.",
    solutions_departmental_improvement: "Mejorar cada departamento.",
    solutions_establish_company_culture: "Establecer una cultura empresarial.",
    solutions_team_expansion_and_training: "Ampliar y formar el equipo.",

    solutions_financial_state_enhancement: "Mejora de Estados financieros.",
    solutions_process_optimization: "Optimización de procesos.",
    solutions_customer_care: "Atención al cliente.",
    solutions_risk_management: "Control de riesgos.",

    calendar_calendar: "Calendario",
    calendar_text: "Agenda tu llamada conmigo",

    contact_website: "Website:",
    contact_email: "E-Mail:",
    contact_address: "Dirección:",

    contact_legal_conditions: "Condiciones legales"
}
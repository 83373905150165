import React from 'react';
import styles from './styles.module.scss';
import BottomVector from 'components/svg/BottomVector';
import TopVector from 'components/svg/TopVector';

function Page({ children, hasBottom, margin, hasTop }) {

    return (
        <section className={styles.main}>

            {hasTop
                ? <div
                    className={styles.svgTop}
                > <TopVector></TopVector>
                </div>
                : undefined}

            <article className={styles.container}>
                {children}
            </article>

            {hasBottom
                ? <div
                    className={styles.svgBottom}
                    style={margin ? { margin: `${margin}` } : undefined}
                > <BottomVector></BottomVector>
                </div>
                : undefined
            }
        </section>
    )
}

export default Page;

import React from 'react'
import styles from './styles.module.scss';
import { InlineWidget } from 'react-calendly';
import { FormattedMessage } from 'react-intl';

function Calendar({reference}) {
  return (
    <section ref={reference} className={styles.main}>
      <h2 className={styles.title}><FormattedMessage id="calendar_calendar"></FormattedMessage></h2>
      <p className={styles.text}><FormattedMessage id="calendar_text"></FormattedMessage></p>
      <InlineWidget 
        url="https://calendly.com/abzulliger" 
        style={{minWidth:"320px", height:"700px;"}}>
      </InlineWidget>
    </section>
  )
}

export default Calendar
import React from 'react'
import styles from './styles.module.scss';
import classList from 'scripts/classList';

function Card({ children, imgUrl, vertical, customStyles }) {
    return (
        <article style={{...customStyles}} className={classList({
            'main': true,
            'vertical': vertical === true,
          },styles)}>
            <img className={styles.img} src={imgUrl} alt="una card, ¿Qué esperas?"/>
            <div className={styles.container}>
                {children}
            </div>
        </article>
    )
}

export default Card;

import React from 'react'

function TopVector() {
    return (
    <svg version="1.1" viewBox="0 0 508 52.782" xmlns="http://www.w3.org/2000/svg" style={{backgroundColor: "#FFFFFF"}}>
        <g transform="translate(152.82 -100.68)">
            <path d="m-119.39 113.68c-27.909-0.0275-33.492 4.4244-33.433 15.423v37.359h508v-45.059s1.5875 23.125-148.85 16.299c-91.308-4.1275-161.58-12.806-212.22-16.801-56.57-4.4681-91.783-7.1994-113.49-7.2207z" fill="#040c39" strokeWidth="1"/>
        </g>
    </svg>
    )
}

export default TopVector
